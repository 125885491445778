<template>
  <div class="elv-valuation-record">
    <div class="elv-valuation-record-content">
      <div class="elv-valuation-record-content-operation">
        <TextSelectFilter v-model="type" :title="t('common.type')" :options="typeOptions" @change="getDataList()" />
      </div>
      <div class="elv-valuation-record-content-list">
        <ListTable
          ref="adjustingTableRef"
          :params="matchParams"
          table-height="calc(100vh - 440px)"
          :table-data="dataResult"
          :table-loading="tableLoading"
          @on-change-page="onChangePage"
          @on-change-page-size="onChangePageSize"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import ListTable from './ListTable.vue'
import ValuationApi from '@/api/ValuationApi'
import { useEntityStore } from '@/stores/modules/entity'
import TextSelectFilter from '@/components/Base/Filter/TextSelect.vue'

const entityStore = useEntityStore()

const tableLoading = ref(false)
const adjustingTableRef = ref()
const matchParams = ref({
  page: 1,
  limit: 20
})

const { t } = useI18n()
const dataResult = ref<any>({ list: [], total: 0 })

const entityId = computed(() => {
  return String(entityStore.entityDetail?.entityId)
})

const type = ref('ALL')

const typeOptions = ref([
  {
    label: 'All',
    value: 'ALL'
  },
  {
    label: 'Asset',
    value: 'ASSET'
  },
  {
    label: 'Liability',
    value: 'LIABILITY'
  }
])

const getDataList = async () => {
  try {
    tableLoading.value = true
    const summaryParams: any = {
      ...matchParams.value
    }

    if (type.value !== 'ALL') {
      summaryParams.type = type.value
    }

    const result = await ValuationApi.getFiatAdjustingList(entityId.value, summaryParams)
    dataResult.value.total = result.data.total
    dataResult.value.list = result.data.list.map((item: any, index: number) => {
      return {
        ...item,
        rowIndex: index
      }
    })
    adjustingTableRef.value?.ref?.setScrollTop(0)
  } catch (error) {
    console.log(error)
  } finally {
    tableLoading.value = false
  }
}

const onChangePageSize = (limit: number) => {
  matchParams.value.limit = limit
  matchParams.value.page = 1
  getDataList()
}

const onChangePage = (page: number) => {
  matchParams.value.page = page
  getDataList()
}

onMounted(() => {
  getDataList()
})
</script>
<style lang="scss">
.elv-valuation-record {
  &-title {
    padding: 15px 24px;
    flex-direction: column;
    align-items: flex-start;

    color: #0e0f11;
    font-family: 'Plus Jakarta Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
  }

  &-content-operation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    height: 32px;
    margin-top: 18px;
    margin-bottom: 8px;
    padding: 0 24px;
  }

  &-content-list {
    padding: 0 24px;
  }
}
</style>
