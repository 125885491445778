<template>
  <section class="elv-valuation-fiat-detail-page">
    <Records :list="typeList" @onClick="onClickType" />

    <div class="elv-valuation-table-header">
      <span>{{ t('valuation.summary') }}</span>
    </div>

    <div class="elv-valuation-filter">
      <FilterDate @on-change-time="onChangeTime" />

      <elv-export-button
        :params="{
          type: 'VALUATION',
          extra: { type: 'FIAT', filter: { beginDate: beginDate, endDate: endDate } }
        }"
        :no-permission="false"
      />
    </div>

    <SummaryTable
      :params="matchParams"
      :table-height="tableHeight"
      :table-data="formatedData"
      :table-loading="tableLoading"
      @on-change-page="onChangePage"
      @on-change-page-size="onChangePageSize"
    />
    <el-dialog
      v-model="showDialog"
      width="75%"
      :show-close="true"
      align-center
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      class="elv-record-dialog"
      @closed="onCloseDialog"
    >
      <template #header="{ titleId }">
        <h4 :id="titleId" class="elv-record-dialog-title">{{ transformI18n(getTypeTitle(currentType)) }}</h4>
      </template>
      <Adjusting v-if="currentType == 'adjusting'" />
    </el-dialog>
  </section>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { isEmpty } from 'lodash-es'
import timezone from 'dayjs/plugin/timezone'
import ValuationApi from '@/api/ValuationApi'
import Records from '../components/Record.vue'
import { $t, transformI18n } from '@/i18n/index'
import { useEntityStore } from '@/stores/modules/entity'
import SummaryTable from './components/SummaryTable.vue'
import Adjusting from './components/Adjusting/Index.vue'
import FilterDate from '@/components/Base/Filter/Date.vue'
import ElvExportButton from '@/components/Base/ElvExportButton.vue'

dayjs.extend(utc)
dayjs.extend(timezone)
const { t } = useI18n()
const route = useRoute()
const entityStore = useEntityStore()

const typeList = [
  {
    type: 'adjusting',
    title: $t('valuation.fiat.adjusting'),
    content: $t('valuation.crypto.fiatAdjustingMemo')
  }
]

const currentType = ref('')
const showDialog = ref(false)
const tableLoading = ref(false)
const matchParams = ref({
  page: 1,
  limit: 20
})
const dataResult = ref<any>({ assets: { list: [] }, liabilities: { list: [] } })

const entityId = computed(() => {
  return route.params?.entityId as string
})

const tableHeight = computed(() => {
  return `calc(100% - 268px)`
})

const beginDate = ref(dayjs(entityStore.entityDetail?.creationDate).format('YYYY-MM-DD'))
const endDate = ref(dayjs().subtract(1, 'day').format('YYYY-MM-DD'))

const formatedData = computed(() => {
  const result: any = []

  if (!dataResult.value) {
    return result
  }

  const { asset, liability } = dataResult.value

  if (!isEmpty(asset)) {
    result.push({
      key: 'ASSET_TITLE',
      index: '',
      type: 'ASSET_TITLE',
      count: asset.list.length,
      ...asset,
      list: asset.list.map((item: any, index: number) => ({ ...item, key: `ASSET_TITLE_${index}`, index: index + 1 }))
    })
  }

  if (!isEmpty(liability)) {
    result.push({
      key: 'LIABILITY_TITLE',
      index: '',
      type: 'LIABILITY_TITLE',
      count: liability.list.length,
      ...liability,
      list: liability.list.map((item: any, index: number) => ({
        ...item,
        key: `LIABILITY_TITLE_${index}`,
        index: index + 1
      }))
    })
  }
  console.log('formatedData', result)
  return result
})

const getTypeTitle = (type: string) => {
  return typeList.find((item) => item.type === type)?.title || ''
}

const onClickType = (item: Record<string, string>) => {
  console.log(item)
  currentType.value = item.type
  showDialog.value = true
}

const onCloseDialog = () => {
  currentType.value = ''
}

const fetchFiatSummaryList = async () => {
  try {
    tableLoading.value = true
    const summaryParams = {
      beginDate: beginDate.value,
      endDate: endDate.value
    }
    const result = await ValuationApi.getFiatSummaryList(entityId.value, summaryParams)
    dataResult.value = result.data
  } catch (error) {
    console.log(error)
  } finally {
    tableLoading.value = false
  }
}

const onChangeTime = (datetimeValue: string[]) => {
  const [res1, res2] = datetimeValue
  beginDate.value = res1
  endDate.value = res2
  fetchFiatSummaryList()
}

const onChangePageSize = (limit: number) => {
  matchParams.value.limit = limit
  matchParams.value.page = 1
  fetchFiatSummaryList()
}

const onChangePage = (page: number) => {
  matchParams.value.page = page
  fetchFiatSummaryList()
}

watch(
  [() => route, () => entityStore.entityDetail?.entityId],
  async () => {
    try {
      if (route.name === 'entity-valuation-fiat') {
        const timezoneData = entityStore.entityDetail?.timezone ?? 'UTC'
        beginDate.value = entityStore.entityDetail?.creationDate
          ? dayjs.tz(entityStore.entityDetail?.creationDate, timezoneData).format('YYYY-MM-DD')
          : dayjs.tz(entityStore.entityDetail?.createdAt, timezoneData).subtract(1, 'day').format('YYYY-MM-DD')
        endDate.value = dayjs().subtract(1, 'day').format('YYYY-MM-DD')
        await fetchFiatSummaryList()
      }
    } catch (error) {
      console.log(error)
    }
  },
  { immediate: true }
)
</script>

<style lang="scss">
.elv-valuation-table-header {
  width: 100%;
  padding: 0 29px 8px 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    color: #000;
    font-family: 'Plus Jakarta Sans';
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

.elv-record-dialog {
  &-title {
    padding: 15px 24px;
    flex-direction: column;
    align-items: flex-start;

    color: #0e0f11;
    font-family: 'Plus Jakarta Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    border-bottom: 1px solid #edf0f3;
  }

  .el-dialog__headerbtn {
    width: 54px;
    height: 54px;
  }

  .el-dialog__header {
    padding: 0;
  }
}

.elv-valuation-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  margin-top: 12px;
  margin-bottom: 16px;
  padding-left: 24px;
  padding-right: 32px;
}
</style>
<style lang="scss" scoped>
.elv-valuation-fiat-detail-page {
  flex: 1;
  min-height: 0;
  position: relative;
}
</style>
