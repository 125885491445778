<template>
  <div class="elv-valuation-popover-price">
    <div class="elv-valuation-popover-price-item">
      <div class="elv-valuation-popover-price-item-left">{{ t('report.priceProvider') }}</div>
      <div class="elv-valuation-popover-price-item-right">
        <div>{{ providerName }}</div>
        <PriceIcon
          v-if="props.priceProvider === 'COINGECKO' || props.priceProvider === 'EXCHANGERATE'"
          :type="props.priceProvider"
        />
      </div>
    </div>
    <div class="elv-valuation-popover-price-item">
      <div class="elv-valuation-popover-price-item-left">{{ t('valuation.crypto.currencyPair') }}</div>
      <div class="elv-valuation-popover-price-item-right">{{ pair }}</div>
    </div>
    <div class="elv-valuation-popover-price-item">
      <div class="elv-valuation-popover-price-item-left">{{ t('report.priceDatetime') }}</div>
      <div class="elv-valuation-popover-price-item-right">{{ beginDatetime }}</div>
    </div>
    <div class="elv-valuation-popover-price-item">
      <div class="elv-valuation-popover-price-item-left">{{ t('common.price') }}</div>
      <div class="elv-valuation-popover-price-item-right">{{ formatNumber(props.priceBeginningValue, 2) }}</div>
    </div>

    <div class="elv-valuation-popover-price-item">
      <div class="elv-valuation-popover-price-item-left">{{ t('report.priceDatetime') }}</div>
      <div class="elv-valuation-popover-price-item-right">{{ endDatetime }}</div>
    </div>
    <div class="elv-valuation-popover-price-item">
      <div class="elv-valuation-popover-price-item-left">{{ t('common.price') }}</div>
      <div class="elv-valuation-popover-price-item-right">{{ formatNumber(props.priceEndingValue, 2) }}</div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import dayjs from 'dayjs'
import { useI18n } from 'vue-i18n'
import PriceIcon from './PriceIcon.vue'
import { formatNumber } from '@/lib/utils'

const props: any = defineProps<{
  priceProvider: string
  priceBeginningDatetime: string
  priceBeginningValue: string
  priceEndingDatetime: string
  priceEndingValue: string
  priceBase: string
  priceTarget: string
}>()

const { t } = useI18n()

const providerName = computed(() => {
  switch (props.priceProvider) {
    case 'COINGECKO':
      return 'Coingecko'
    case 'EXCHANGERATE':
      return 'ExchangeRateApi'
    case 'TRADE':
      return 'Trade'
    case 'SALE_COST_BASIS':
      return 'Sale Cost Basis'
    case 'CUSTOM':
      return 'Customized Price'
    default:
      return props.providerType
  }
})

const beginDatetime = computed(() => {
  return dayjs.tz(props.priceBeginningDatetime, 'UTC').format('YYYY/MM/DD HH:mm')
})

const endDatetime = computed(() => {
  return dayjs.tz(props.priceEndingDatetime, 'UTC').format('YYYY/MM/DD HH:mm')
})

const pair = computed(() => {
  return `${props.priceTarget}/${props.priceBase}`
})
</script>
<style lang="scss">
.elv-valuation-popover-price {
  display: flex;
  width: 250px;
  flex-direction: column;
  align-items: flex-start;

  &-item {
    display: flex;
    justify-content: space-between;
    height: 15px;
    width: 100%;
    margin-bottom: 8px;

    &-left {
      color: #1e2024;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
    }

    &-right {
      color: #0e0f11;
      font-family: 'Barlow';
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
      display: flex;

      img {
        margin-left: 4px;
        width: 14px;
        height: 14px;
      }
    }
  }
}
</style>
